img {
	border: none;
	-ms-interpolation-mode: bicubic;
	max-width: 100%;
}

body {
	background-color: #000;
	-webkit-font-smoothing: antialiased;
	font-size: 14px;
	line-height: 1.4;
	margin: 0;
	padding: 0;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-family: 'GraphikSemibold', Arial, sans-serif;
}

h1,
h2,
h3,
h4 {
	color: #000000;
	font-weight: 400;
	line-height: 1.4;
	margin: 0;
	margin-bottom: 30px;
}

h1 {
	font-size: 35px;
	font-weight: 300;
	text-align: center;
	text-transform: capitalize;
}

/* p,
ul,
ol {
	font-size: 18px;
	margin: 0;
	margin-bottom: 15px;
	font-family: 'GraphikRegular', Arial, sans-serif;
} */

p,
ul,
ol {
	font-size: 18px;
	margin: 0;
	margin-bottom: 15px;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
}

p li,
ul li,
ol li {
	list-style-position: inside;
	margin-left: 5px;
}

a {
	color: #3498db;
	text-decoration: underline;
}

/* Forgot Password */
.forgot-password .bd-example-modal {
	background-color: inherit;
}

.forgot-password .bd-example {
	border: none;
}

/* .forgot-password .modal-content {
	border-radius: 16px;
} */
.forgot-password .modal-content {
	border-radius: 16px;
	background: #242442;
}

.forgot-password .modal-dialog {
	max-width: 650px;
}

/* .forgot-password .modal-header {
	background-color: #FFB700;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	padding: 1.5rem 2.5rem;
} */

/* .forgot-password .modal-header a {
	font-family: 'GraphikRegular', Arial, sans-serif;
	color: #000;
	text-decoration: none;
	font-size: 18px;
	line-height: 20px;
	padding: 7px 0px;
} */
.forgot-password .modal-header {
	border-color: #FFF624;
	background-color: #FFF624
		/* #FFB700*/
	;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
	padding: 1.5rem 2.5rem;
	align-items: center;
}

.forgot-password .modal-header b {
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	color: #000;
	text-decoration: none;
	font-size: 18px;
	line-height: 20px;
	font-weight: normal;
	/* padding: 7px 0px; */
}

/* .forgot-password .modal-body {
	padding: 3rem 2.5rem;
} */

/* .forgot-password .modal-body h1 {
	text-align: left;
	font-size: 18px;
	font-family: 'GraphikSemibold', Arial, sans-serif;
	line-height: 20px;
	margin: 0 0 15px 0;
} */

.forgot-password .modal-body {
	padding: 3rem 2.5rem;
	background: #242442;
	color: #fff;
	border-bottom-left-radius: 16px;
	border-bottom-right-radius: 16px;
}

.forgot-password .modal-body h1 {
	text-align: left;
	font-size: 18px;
	font-family: 'Forma DJR Micro Bold', sans-serif;
	line-height: 20px;
	margin: 0 0 15px 0;
	color: #fff;
}

.forgot-password .modal-footer {
	border-bottom-right-radius: 16px;
	border-bottom-left-radius: 16px;
}

.pass-pin {
	width: 270px;
	margin: 0 auto 30px;
}

.pass-pin .digit-group input {
	width: 64px;
	height: 64px;
	background-color: rgba(155, 163, 180, 0.15);
	border: 1px solid #9BA3B4;
	border-radius: 8px;
	line-height: 50px;
	text-align: center;
	font-size: 24px;
	font-weight: 200;
	color: #000;
	margin: 0 2px;
}

.pass-pin .digit-group input:focus-visible {
	box-shadow: none;
	border-color: #FFF624;
	outline: none;
}

.pass-pin .prompt {
	margin-bottom: 20px;
	font-size: 20px;
	color: white;
}

.forgot-password .modal-body h2 {
	font-size: 18px;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	color: #fff;
	line-height: 20px;
	margin: 0 0 15px 0;
}

/* .save-btn,
.save-btn:hover {
	background-color: #FFF624;
	border-radius: 8px;
	width: 256px;
	border-color: #FFF624;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 25px;
	line-height: 28px;
	color: #000000;
	padding: 18px;
	margin: 0 auto;
	display: block;
} */

.save-btn,
.save-btn:hover,
.save-btn:focus,
.save-btn:active {
	background-color: #FFF624 !important;
	box-shadow: none !important;
	border-radius: 8px;
	width: 256px;
	border-color: #FFF624 !important;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	font-style: normal;
	font-size: 25px;
	line-height: 28px;
	color: #000000 !important;
	padding: 18px;
	margin: 0 auto;
	display: block;
}

.save-btn:focus,
.save-btn:focus,
.save-btn:not(:disabled):not(.disabled):active {
	color: #000;
	background-color: #FFF624;
	border-color: #FFF624;
	box-shadow: none
}

.locks-pin .digit-group input {
	background-color: rgba(237, 0, 0, 0.1);
	border-color: #ED0000;
}

.error-mssg {
	font-style: normal;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	font-size: 14px;
	line-height: 15px;
	color: #f97657;
	display: block;
	margin-top: 10px;
}

.locks-pin .digit-group input:focus-visible {
	box-shadow: none;
	border-color: #ED0000;
	outline: none;
}

.disabled-btn {
	opacity: 0.6;
}

/* .go-back {
	margin-top: 35px;
} */

.go-back,
.go-back:hover,
.go-back:focus {
	margin-top: 35px;
	background-color: #FFF624;
	border-color: #FFF624;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	color: #000000;
	box-shadow: none;
}

/* End Forgot Password */

/* Start Share Photo */
.share-photo-header {
	background: #FFF624;
	padding: 20px 50px;
	display: flex;
	align-items: center;
}

.share-photo-header img,
.share-photo-header h2 {
	display: inline-block;
}

.share-photo-header img {
	margin-right: 20px;
}

/* .share-photo-header h2 {
	font-style: normal;
	font-family: "GraphikSemibold";
	font-size: 24px;
	line-height: 40px;
	margin: 0;
} */
.share-photo-header h2 {
	font-style: normal;
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	font-weight: normal;
	color: #323232;
	font-size: 30px;
	line-height: 40px;
	margin: 0;
}

.center-photo-popup {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
	overflow-x: hidden;
	overflow-y: auto;
}

.common {
	/* height: 100%; */
	padding-top: 30px
}

.center1 .parent {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 440px;
}

.center-text-inner {
	width: 440px;
	border: 16px solid #fff;
	border-radius: 8px;
	background-color: #fff;
}

/* .center-text h2 {
	font-family: 'GraphikLight';
	font-style: normal;
	font-size: 40px;
	line-height: 44px;
	text-align: center;
	margin: 45px 0;
} */
.center-text h2 {
	font-family: 'Forma DJR Micro Medium';
	font-style: normal;
	font-size: 40px;
	line-height: 44px;
	text-align: center;
	margin: 25px 0;
}

.center-text .save-btn {
	width: 100%;
	margin: 20px 0 0 0;
}

/* .center-text span {
	font-family: 'GraphikLight';
	font-style: normal;
	color: #fff;
	margin: 20px 0;
	font-size: 24px;
} */

.center-text span {
	font-family: 'Forma DJR Micro', Arial, sans-serif;
	font-style: normal;
	color: #fff;
	margin: 20px 0;
	font-size: 25px;
}

/* End Share Photo */

/* Start Web Zip File */
.web-zip-file {
	/* position: fixed; */
	position: relative;
	top: 0;
	left: 0;
	z-index: 1050;
	display: block;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}

.web-zip-file .center-text-inner {
	background: #313155;
	width: auto;
	border: none;
	padding: 50px 88px;
	border-radius: 20px;
}

.web-zip-file .center-text-inner h2 {
	font-family: 'Forma DJR Micro', sans-serif;
	color: #fff;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 40px;
	text-align: left;
	margin: 25px 0;
}

.web-zip-file .preview-details {
	display: flex;
	align-items: center;
}

.web-zip-file .center-text-inner .save-btn,
.web-zip-file .center-text-inner .save-btn:hover {
	font-family: 'Forma DJR Micro', sans-serif;
	background-color: #FFF624;
	border-color: #FFF624;
	width: 100%;
	margin: 30px 0;
	padding: 18px 80px;
	color: #242442;
	font-size: 34px;
}

.web-zip-file .center-text-inner .preview-file-name {
	word-break: break-word;
}

/* .web-zip-file .center-text-inner .save-btn {
	background-color: #fff;
	border-color: #fff;
	width: auto;
	margin: 30px auto 0;
	padding: 18px 80px;
} */

/* .web-zip-file .center-text-inner .save-btn:hover {
	border: 1px solid #fff;
	background: #ffb700;
}

.web-zip-file .center-text-inner h3 img {
	margin-right: 15px;
	position: relative;
	top: -4px;
}

.web-zip-file .center-text-inner h3 em {
	font-style: normal;
	word-wrap: break-word;
}

.web-zip-file .center-text-inner h3 {
	font-family: 'GraphikRegular';
	line-height: 40px;
	font-style: normal;
	font-size: 24px;
	margin: 0;
} */
.web-zip-file .center-text-inner img {
	margin-right: 30px
}

.web-zip-file .center-text-inner h3,
.web-zip-file .center-text-inner h4 {
	font-family: 'Forma DJR Micro Bold', sans-serif;
	line-height: 40px;
	font-style: normal;
	font-size: 24px;
	margin: 0;
	color: #fff;
}

.web-zip-file .center-text-inner h4 {
	font-family: 'Forma DJR Micro', sans-serif;
}

.web-zip-file .center-text-inner h4.preview-date {
	color: #B0B0B0;
}

.web-zip-file .center-text span {
	margin: 40px 0 20px;
}

.web-zip-file .center-text-inner h4 button {
	font-family: 'Forma DJR Micro', sans-serif;
	font-style: normal;
	font-size: 24px;
	line-height: 40px;
	color: #FFF624;
	border: none;
	background: none;
}

.web-zip-file .center-text span {
	margin: 40px 0 20px;
}

.web-zip-file .center-text-inner h3 button {
	font-family: 'GraphikRegular';
	font-style: normal;
	font-size: 24px;
	line-height: 40px;
	color: #0029FF;
	border: none;
	background: none;
}

.web-zip-file.modal .modal-dialog {
	max-width: 760px;
}

.web-zip-file.modal .modal-content {
	background: none;
}

.web-zip-file.modal span {
	font-family: 'Forma DJR Micro', sans-serif;
	font-style: normal;
	color: #B0B0B0;
	margin: 20px 0;
	font-size: 24px;
	margin: 40px 0 20px;
	display: block;
	text-align: center;
}

.modal.show.exampleModalCenter .modal-dialog {
	width: 100%;
	max-width: inherit;
	margin: 0 auto;
	min-height: 100%;
}

.modal.show.exampleModalCenter .modal-content {
	background-color: #000000b3;
	height: 100%;
}

.modal.exampleModalCenter .modal-content {
	background-color: inherit;
}

.modal-middle {
	max-width: 940px;
	margin: 0 auto;
	padding: 50px 0;
}

.modal.show.exampleModalCenter p {
	color: #fff;
	display: block;
}

.modal.show.exampleModalCenter .modal-body img {
	margin: 20px 18px;
}

.modal.show.exampleModalCenter .modal-body {
	padding: 0;
}

.modal-inner-body {
	margin: 0px -20px;
}

.modal.show.exampleModalCenter .modal-header {
	padding: 1rem 0;
	border-color: #B5BCCB;
	margin-bottom: 20px;
	align-items: center;
}

.modal.show.exampleModalCenter .modal-header p {
	font-family: 'Forma DJR Micro Bold';
	font-style: normal;
	font-size: 25px;
	line-height: 26px;
	color: #FFFFFF;
}

/* .modal.show.exampleModalCenter .modal-header p span {
	font-family: 'GraphikRegular';
	font-style: normal;
	font-size: 16px;
	line-height: 18px;
	text-transform: uppercase;
	color: #9BA3B4;
} */
.modal.show.exampleModalCenter .modal-header p span {
	/* font-family: 'GraphikRegular';
    font-style: normal; */
	font-family: 'Forma DJR Micro';
	font-size: 18px;
	line-height: 18px;
	/* text-transform: uppercase; */
	color: #9BA3B4;
}

.modal.show.exampleModalCenter .modal-header p span:first-child {
	margin-right: 50px;
}

.modal.show.exampleModalCenter .modal-header .close {
	margin: 0;
	background: #FFB700;
	opacity: 1;
	text-shadow: none;
	font-size: 2rem;
	padding: 3px 11px;
	border-radius: 8px;
	font-weight: normal;
}

.modal.show.exampleModalCenter .modal-header .close span {
	font-weight: normal;
}

.modal.show.exampleModalCenter .modal-header .close-remove {
	border: none;
	background: none;
	box-shadow: none;
	padding: 0;
}

/* Responsive css start */

/* @media (max-width: 1024px) {
	.center-text {
		width: 30%;
	}

	.center-text-inner {
		width: auto;
	}

	.center-text h2 {
		margin: 20px 0 10px;
	}

	.center-text span {
		font-size: 23px;
	}

	.center-text h2 {
		font-size: 30px;
		line-height: inherit;
	}
} */
@media (max-width: 1400px) {
	.center-text {
		width: 30%;
	}

	.center-text-inner {
		width: auto;
	}

	.center-text h2 {
		margin: 20px 0 10px;
	}

	.center-text span {
		font-size: 23px;
	}

	.center-text h2 {
		font-size: 30px;
		line-height: inherit;
	}

	.share-photo-header {
		padding: 15px 50px
	}
}

@media (max-width: 840px) {
	.modal.exampleModalCenter {
		padding: 0 !important
	}

	.modal-middle {
		max-width: inherit;
		padding: 50px;
	}

	.modal.show.exampleModalCenter .modal-body img {
		width: 28.3%;
	}
}

@media (max-width: 768px) {
	.modal.show.exampleModalCenter .modal-body img {
		width: 27.9%;
	}

	.modal.show.exampleModalCenter .modal-inner-body {
		text-align: center;
	}

	.center-text {
		width: 35%;
	}
}

@media (max-width: 667px) {
	.center-text {
		width: 45%;
	}
}

@media (max-width: 640px) {
	.modal.show.exampleModalCenter .modal-body img {
		width: 26%;
	}
}

@media (max-width: 568px) {
	.modal.show.exampleModalCenter .modal-body img {
		width: 25%;
	}
}

@media (max-width: 500px) {
	.modal.show.exampleModalCenter .modal-body img {
		width: 40%;
	}

	.web-zip-file .center-text-inner img.hp-logo {
		width: 65px;
		height: 65px;

	}

	.modal.show.exampleModalCenter .modal-header .close {
		position: fixed;
		top: 20px;
		right: 20px;
	}

	.center-text {
		width: 55%;
	}
}

@media (max-width: 420px) {
	.modal.show.exampleModalCenter .modal-body img {
		margin: 15px
	}
}

@media (max-width: 414px) {
	.modal.show.exampleModalCenter .modal-body img {
		width: 60%;
	}
}

@media (max-width: 320px) {
	.modal.show.exampleModalCenter .modal-header p {
		font-size: 19px;
	}

	.modal.show.exampleModalCenter .modal-header p span:first-child {
		display: block;
	}

	.modal.show.exampleModalCenter .modal-body img {
		width: 75%;
	}

	.modal.show.exampleModalCenter .modal-header .close {
		font-size: 25px;
		padding: 5px 11px;
	}
}

@media (max-width: 640px) {
	.web-zip-file .center-text-inner {
		padding: 50px;
	}
}

@media (max-width: 480px) {
	.forgot-password .modal-header {
		padding: 1.5rem 1.5rem;
		justify-content: inherit;
		display: block;
	}

	.forgot-password .modal-header a {
		margin-top: 15px;
		padding: 0;
		display: block;
	}

	.forgot-password .modal-body {
		padding: 2rem 1.5rem;
	}

	.web-zip-file .center-text-inner img.logowithouttext {
		width: 20%;
		height: auto;
	}

	.web-zip-file .center-text-inner h2 {
		line-height: inherit;
		font-size: 20px;
		margin: 15px 0;
	}

	.web-zip-file .center-text-inner h3,
	.web-zip-file .center-text-inner h3 button {
		line-height: inherit;
		font-size: 20px;
	}

	.web-zip-file.modal span {
		font-size: 20px;
		margin: 20px 0
	}

	.web-zip-file .center-text-inner {
		padding: 30px;
	}

	.web-zip-file .center-text-inner .save-btn,
	.center-text .save-btn,
	.web-zip-file .center-text-inner .save-btn, .web-zip-file .center-text-inner .save-btn:hover {
		/* width: auto; */
		margin: 30px 0;
		padding: 12px 25px;
		font-size: 18px;
	}

	.center-text .save-btn {
		width: 100%;
	}
}

@media (max-width: 420px) {
	.center-text {
		width: 75%;
	}

	.share-photo-header h2 {
		font-size: 19px;
	}
}

@media (max-width: 360px) {
	.share-photo-header h2 {
		font-size: 17px;
	}
}

@media (max-width: 320px) {
	.pass-pin .digit-group input {
		width: 57px;
		height: 57px;
	}

	.web-zip-file .center-text-inner img.logowithouttext {
		width: 25%;
	}

	.web-zip-file .center-text-inner h2 {
		font-size: 19px;
	}

	.web-zip-file .center-text-inner h3,
	.web-zip-file .center-text-inner h3 button {
		font-size: 18px;
	}

	.share-photo-header h2 {
		font-size: 15px;
	}

	.share-photo-header img {
		/* width: 50px; */
		margin-right: 10px;
		/* height: 40px; */
	}
}

@font-face {
	font-family: "FormaDJRMicroRegularTesting";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicroRegularTesting.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicroRegularTesting.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicroRegularTesting.otf") format("opentype"), ;
}

@font-face {
	font-family: "SFPRODISPLAYREGULAR";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/SFPRODISPLAYREGULAR.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/SFPRODISPLAYREGULAR.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/SFPRODISPLAYREGULAR.otf") format("opentype"), ;
}

@font-face {
	font-family: "GraphikRegular";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.eot");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.otf") format("opentype"), ;
}

@font-face {
	font-family: "GraphikMedium";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikMedium.eot");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikMedium.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikMedium.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikMedium.otf") format("opentype"), ;
}

@font-face {
	font-family: "GraphikRegular";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.eot");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikRegular.otf") format("opentype"), ;
}

@font-face {
	font-family: "GraphikLight";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikLight.eot");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikLight.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikLight.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikLight.otf") format("opentype"), ;
}

@font-face {
	font-family: "GraphikThin";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikThin.eot");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikThin.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikThin.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikThin.otf") format("opentype"), ;
}

@font-face {
	font-family: "GraphikSemibold";
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikSemibold.eot");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikSemibold.ttf");
	src: url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikSemibold.woff") format("woff"), url("https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/GraphikSemibold.otf") format("opentype"), ;
}

@font-face {
	font-family: 'Forma DJR Micro Bold';
	src: url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Bold.woff2') format('woff2'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Bold.woff') format('woff'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Forma DJR Micro Medium';
	src: url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Medium.woff2') format('woff2'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Medium.woff') format('woff'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Medium.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Forma DJR Micro';
	src: url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Regular.eot');
	src: local('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/Forma DJR Micro Regular'), local('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Regular'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Regular.eot?#iefix') format('embedded-opentype'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Regular.woff2') format('woff2'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Regular.woff') format('woff'),
		url('https://kodak-media-processed.s3.us-east-2.amazonaws.com/fonts/FormaDJRMicro-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.overlay {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	background: #000;
	z-index: 1073;
}

.overlay__inner {
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	position: absolute;
}

.overlay__content {
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);

}

.spinner {
	width: 75px;
	height: 75px;
	display: inline-block;
	border-width: 2px;
	border-color: rgba(255, 255, 255, 0.05);
	border-top-color: #fff;
	animation: spin 1s infinite linear;
	border-radius: 100%;
	border-style: solid;
}

@keyframes spin {
	100% {
		transform: rotate(360deg);
	}
}

/* .hp-header {
	background: #0096D6
} */

/* .hp-header h2 {
	color: #fff;
	font-family: 'FormaDJRMicroRegularTesting';
	font-weight: normal;
} */

/* .hp-download-btn,
.hp-download-btn:hover,
.hp-download-btn:focus,
.hp-download-btn:focus,
.hp-download-btn:not(:disabled):not(.disabled):active {
	background: #0096D6;
	color: #fff;
	border: none;
	outline: none;
	font-family: 'FormaDJRMicroRegularTesting';
	font-size: 25px
}

.center-text span.link-expire {
	font-family: 'FormaDJRMicroRegularTesting'
} */